class UserField {
  constructor() {
    this.rutInput = document.querySelector('input#rut')
  }
  start() {
    this.formatRut()
  }
  formatRut() {
    this.rutInput.addEventListener('change', () => {
      value = this.rutInput.value;
      if (value=== '10-1') {
        return value
      }
        
      this.rutInput.value = value.replace(/[.-]/g, "")
                                 .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
      //this.verifyDigit(this.rutInput.value)
    
    })
  }
  verifyDigit(rut) {
    const digit = parseInt(rut.split('-')[1]);
    const digitV = this.calculateDigit(rut);
    return digit == digitV ? true : 'El rut ingresado no es válido';
  }
  calculateDigit(rut) {
    let factor = 2;
    let sum = 0;
    let nums = rut.split('-')[0].replace(/\./g, "");
    for (let i = 0; i < nums.length; i++) {
      const divisor = nums.split('').reverse();
      sum += parseInt(divisor[i]) * factor;
      factor = factor == 7 ? 2 : factor + 1;
    }
    const sumNums = Math.floor(sum / 11)
    const resto = sum - (11 * sumNums)
    const endDigit = 11 - resto
    if (endDigit == 11)
    { return 0 }
    else if (endDigit == 10)
    { return 'k' }
    else
    { return endDigit }
  }  
}
document.addEventListener('turbolinks:load', () => {
  const rutInput = document.querySelector('input#rut')
  if(rutInput == null) { return }
  new UserField().start()
})