class ModalToggler {
  constructor(element){
    this.element = element
    this.toggleModal = this.toggleModal.bind(this)
    this.hide = this.hide.bind(this)
    this.show = this.show.bind(this)
    this.dismiss = this.dismiss.bind(this)
    this.shown = false
    this.modal = null
  }

  start() {
    this.element.addEventListener('click', this.toggleModal)
    const modalIdentifier = this.element.dataset.target
    this.modal = document.querySelector(modalIdentifier)

    const dismissButtons = [...this.modal.querySelectorAll('[data-dismiss="modal"]')]
    dismissButtons.map( btn => btn.addEventListener('click', this.dismiss) )
  }

  dismiss(event) {
    if(event) {
      event.preventDefault()
    }
    this.hide()
  }

  show() {
    const body = document.querySelector('body')
    body.classList.add('modal-open')

    const backdrop = document.createElement('div')
    backdrop.classList.add('modal-backdrop', 'fade', 'show')
    backdrop.addEventListener('click', this.dismiss)
    body.appendChild(backdrop)

    this.modal.classList.add('show')
    this.modal.style['display'] = 'block'
    this.modal.style['padding-right'] = '15px'
  }

  hide() {
    const body = document.querySelector('body')
    this.modal.classList.remove('show')
    this.modal.style['display'] = 'none'

    const backdrop = document.querySelector('.modal-backdrop')
    body.removeChild(backdrop)
    body.classList.remove('modal-open')
  }

  toggleModal() {
    
    if(!this.modal) { return }

    this.shown ?  this.hide() : this.show()
    
  }
}
document.addEventListener('turbolinks:load', () => {
  const modals = [...document.querySelectorAll('[data-toggle="modal"]')]

  modals.map((element) => {
    new ModalToggler(element).start()
  })
})